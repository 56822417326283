import React from "react";

import { Col, Row, Typography, Layout, Card } from "antd";

function AboutUs() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> About Us</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "35px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                }}
              >
                {
                  <>
                    We are consultants, and trainers.
                    <br />
                    This is an online learning community.
                  </>
                }
              </Typography.Title>
              <Typography.Text
                className="fontFamily1"
                style={{ fontSize: "16px", color: "grey", textAlign: "left" }}
              >
                {
                  <>
                    Excellence Academy's mission is to inspire and develop
                    organizations and their staff to achieve continues quality
                    improvement goals— one person, one question, and one small
                    commitment to learning at a time.
                    <br />
                    <br />
                    We enable a great team of subject matter experts,
                    practitioners and facilitators experienced in Head Start,
                    Early Head Start, Day Care and Family Childcare, to provide
                    an interactive learning experience. Our courses explore laws
                    and regulatory compliance that shape early care and
                    education program governance and design, human resources,
                    facility management and health and safety into a delightful
                    experience of guided discovery.
                  </>
                }
              </Typography.Text>
              <br /> <br />
              <Card
                className="aboutCard1"
                bordered={false}
                style={{
                  width: "100%",
                  height: "650px",
                }}
              ></Card>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default AboutUs;
