import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row, Typography,
  Layout, Image,
  Card,
  Spin, Input,message
} from "antd";
import { useNavigate, useParams } from "react-router";
import { UPLOADS_URL, MEETING } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import swal from "sweetalert";
import "react-alice-carousel/lib/alice-carousel.css";
import dayjs from "dayjs";
import { BsDot } from "react-icons/bs";
//icons

function MeetingDetails() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [reviews,setReviews] = useState([])
  const [ratings,setRatings] = useState({})
  const [meeting,setMeeting]= useState({})
  const days = ["Sunday","Monday",'Tuesday',"Wednesday","Thursday","Friday","Saturday"]
  const { Search } = Input;
  const [loading, setLoading] = useState(true);
  const [meetings, setMeetings] = useState([]);
  const [range, setRange] = useState([10, 200]);
  const [review, setReview] = useState({
    averageRating: 0,
    totalReviews: 0
  });
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    meetingingRate: 0,
  });

  const item = { rating: 4 };

  useEffect(()=>{
    getMeetingDetails();
    if(token){
      getMyMeetings()
    }

  },[])


  console.log("setMeetings",meetings)


  const getMyMeetings = async (pageNumber, keyword, category) => {
    setLoading(true);
    try {
      const response = await Get(MEETING.getMyMeetings,token, {
        limit: "100",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setMeetings(response?.data?.meetings?.map(item => item._id));
       
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const getMeetingDetails = async () =>{
    try {
      const response = await Get(MEETING.getMeetingById + id, token);
      if (response?.status) {
        setMeeting(response?.data);
      } else {
        swal("Error", response?.message, "error");
       
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  console.log("review",reviews);


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<> Meeting Details</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "10px",
            }}
          >
            <Card className="contactCard2" bordered={false}>
            {loading && <Row style={{minHeight:"30vh"}} className="flex">
              <Spin size="large" />
                </Row>}
             {!loading && <Row
                style={{
                  width: "100%",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Title
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                 {meeting?.meeting?.title}
                </Typography.Text>
                </Col>

             


                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Id
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                 {meeting?.meeting?.meetingId || "-"}
                </Typography.Text>
                </Col>

                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Link
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                 {meeting?.meeting?.meetingLink || "-"}
                </Typography.Text>
                </Col>

                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Passcode
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                {meeting?.meeting?.passcode || "-"}
                </Typography.Text>
                </Col>


                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Date
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                             {dayjs(meeting?.meeting?.meetingDate).format("DD MMMM, YYYY ")}    
                </Typography.Text>
                </Col>

                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Meeting Time
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                   {dayjs(meeting?.meeting?.meetingTime).format("HH:MM A")}    
                 {/* {meeting?.meeting?.meetingTime} */}
                </Typography.Text>
                </Col>

                <Col xs={24} md={12}>
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 30,
                  }}
                >
                 Description
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    textAlign:'justify'
                  }}
                >
                   {meeting?.meeting?.description}    
                 {/* {meeting?.meeting?.meetingTime} */}
                </Typography.Text>
                </Col>
                   
              </Row>}
            </Card>
          </div>
        </Col>
       
      </Row>
    </Layout>
  );
}

export default MeetingDetails;
